import languages from '../intl/index.json';

import en from '../intl/en.json';
import nl from '../intl/nl.json';

export const getLocale = () => {
  const locale = typeof window !== 'undefined' ? window.location.pathname.split('/')[1] : '';
  const currentLanguage = languages.langs.includes(locale) ? locale : languages.defaultLangKey;
  return currentLanguage;
};

export const getSwitchLink = () => {
  if (typeof window === 'undefined') return;
  const pathname = window.location.pathname;
  const guess = pathname.split('/')[1];

  const { langs, defaultLangKey } = languages;
  if (langs.includes(guess)) return '/' + (pathname.replace(/^\/[^/]+\/?/, '') || '');

  const currentLang = defaultLangKey;
  const otherLang = langs.find((lang) => lang !== currentLang);
  const link = '/' + otherLang + pathname;

  return link;
};

export const getMessages = () => ({ nl, en }[getLocale()]);

export const transformHref = (href) => {
  if (href[0] !== '/') return href;

  const locale = typeof window !== 'undefined' ? window.location.pathname.split('/')[1] : '';
  return languages.langs.includes(locale) ? '/' + locale + href : href;
};
