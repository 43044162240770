import React from 'react';
import PropTypes from 'prop-types';

import { IntlProvider } from 'react-intl';

import CookieBar from '../cookie-bar';
import Footer from '../footer';

import 'fontsource-inter';
import 'fontsource-inter/500.css';
import 'fontsource-inter/600.css';
import 'fontsource-inter/700.css';

import { getLocale, getMessages } from '../../tools/language.js';

import '../../styles/index.scss';

const Layout = ({ children }) => {
  return (
    <IntlProvider locale={getLocale()} messages={getMessages()}>
      <CookieBar />
      {children}
      <Footer />
    </IntlProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
};

export default Layout;
