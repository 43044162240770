import React from 'react';

import { FormattedMessage, useIntl } from 'react-intl';
import Link from '../link';
import { SecondaryLink } from '../buttons';

import BavakLogo from '../../../static/images/logo.svg';
import LinkedInIcon from '../../../static/icons/linkedin.svg';
import TwitterIcon from '../../../static/icons/twitter.svg';
import YouTubeIcon from '../../../static/icons/youtube.svg';

import './index.scss';

const FooterNavigationLink = ({ to, id }) => {
  return (
    <SecondaryLink to={to}>
      <FormattedMessage id={id} />
    </SecondaryLink>
  );
};

const Footer = () => {
  const intl = useIntl();

  return (
    <footer>
      <nav>
        <Link to="/">
          <img src={BavakLogo} alt="Bavak Logo" />
        </Link>
        <FooterNavigationLink to="/solutions/" id="solutions" />
        <FooterNavigationLink to="/sectors/" id="sectors" />
        <FooterNavigationLink to="/support/" id="support" />
        <FooterNavigationLink to="/about/" id="about" />
        <FooterNavigationLink to="/news/" id="news" />
        <FooterNavigationLink to="/contact/" id="contact" />
        <nav id="social">
          <a href={intl.formatMessage({ id: 'linkedin-link' })} target="_blank" rel="noreferrer">
            <img src={LinkedInIcon} alt="Link to the LinkedIn account of Bavak Security Group." />
          </a>
          <a href="https://twitter.com/bavak" target="_blank" rel="noreferrer">
            <img src={TwitterIcon} alt="Link to the Twitter account of Bavak Security Group." />
          </a>
          <a
            href="https://www.youtube.com/channel/UCKIgfqN_7fMZwJQZkpfpa2Q/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={YouTubeIcon} alt="Link to the YouTube channel of Bavak Security Group." />
          </a>
        </nav>
      </nav>
      <div id="small-letters">
        <p>&copy; {new Date().getFullYear()} Bavak Security Group</p>
        <Link to="/terms/">
          <FormattedMessage id="terms" />
        </Link>
        <Link to="/privacy-statement/">
          <FormattedMessage id="privacy-statement" />
        </Link>
      </div>
    </footer>
  );
};

export default Footer;
