import React from 'react';
import PropTypes from 'prop-types';

import Link from '../link';

import './index.scss';

const Button = ({ to, children, type, ...props }) => {
  if (to)
    return (
      <Link to={to} className={type} {...props}>
        {children}
      </Link>
    );
  else
    return (
      <button className={type} {...props}>
        {children}
      </button>
    );
};

export const PrimaryButton = (props) => {
  return <Button type="primary-button" {...props} />;
};

export const SecondaryButton = (props) => {
  return <Button type="secondary-button" {...props} />;
};

export const PrimaryLink = (props) => {
  return <Button type="primary-link" {...props} />;
};

export const SecondaryLink = (props) => {
  return <Button type="secondary-link" {...props} />;
};

PrimaryButton.propTypes = SecondaryButton.propTypes = PrimaryLink.propTypes = SecondaryLink.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node.isRequired,
  norewrite: PropTypes.bool,
};
