import React from 'react';
import PropTypes from 'prop-types';

import Link from 'gatsby-link';

import { transformHref } from '../../tools/language.js';

export default function ({ to, children, norewrite, ...props }) {
  const href = norewrite ? to : transformHref(to);
  return (
    <Link to={href} {...props}>
      {children}
    </Link>
  );
}

Link.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  norewrite: PropTypes.bool,
};
