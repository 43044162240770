import React from 'react';
import CookieConsent from 'react-cookie-consent';
import { FormattedMessage } from 'react-intl';
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';
import { useLocation } from '@reach/router'; // this helps tracking the location

import './index.scss';

const CookieBar = () => {
  const location = useLocation();

  return (
    <CookieConsent
      disableStyles
      location="bottom"
      buttonText={<FormattedMessage id="cookie-bar-accept" />}
      cookieName={process.env.GDPR_GOOGLE_ANALYTICS}
      containerClasses="cookie-bar"
      buttonClasses="primary-button"
      expires={150}
      onAccept={() => {
        initializeAndTrack(location);
      }}
    >
      <FormattedMessage id="cookie-bar-info" />
    </CookieConsent>
  );
};

export default CookieBar;
